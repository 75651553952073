import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { passRecall } from '@root-auth/store/actions/auth/passRecall'
import MuiButton from '../MuiComponents/MuiButton'

interface IRecall {
	passRecall: (login: string) => void
}

const Recall: React.FC<IRecall> = ({ passRecall }) => {
	const [login, setLogin] = useState('')

	return (
		<div className='login'>
			<Link to='/'>
				<img className='login__image' src='/assets/images/SVG/logo.svg' alt='LOGO' />
			</Link>
			<form
				className='login__form'
				onSubmit={e => {
					e.preventDefault()
					passRecall(login)
				}}
			>
				<h2 className='login__title'>Восстановление пароля</h2>
				<div className='login__fields'>
					<TextField
						label='Email'
						variant='outlined'
						fullWidth
						type='email'
						name='email'
						autoComplete='off'
						onChange={e => setLogin(e.currentTarget.value)}
						value={login}
						inputProps={{ inputMode: 'email' }}
					/>
				</div>
				<div className='login__actions'>
					<MuiButton disabled={login ? false : true} fullWidth variant='contained' color='primary' type='submit'>
						Отправить запрос
					</MuiButton>
					<div className='login__actions-item'>
						Уже есть аккаунт?{' '}
						<Link to='/login' className='link-blue'>
							Войти
						</Link>
					</div>
				</div>
			</form>
		</div>
	)
}

const mapDispatchToProps = (dispatch: any) => ({
	passRecall: (login: string) => dispatch(passRecall(login)),
})

export default connect(null, mapDispatchToProps)(Recall)
