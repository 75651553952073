import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { SET_RESTORE_TOKEN } from '@root-auth/utils/types'
import { showNotify } from '@root-auth/store/actions/notify/notify'

export default function* getRestoreToken({ token }) {
	try {
		const res = yield call(fetchData, `/codes/recall/${token}`, res => res, null, {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json',
		})
		if (res.status === 'success') {
			yield put({ type: SET_RESTORE_TOKEN, restoreToken: res.token })
		}
		if (res.status === 'failure') {
			yield put({ type: SET_RESTORE_TOKEN, restoreToken: undefined })
		}
	} catch (err) {
		yield put(showNotify({ type: 'error', message: 'Произошла ошибка' }))
	}
}
