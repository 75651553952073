export interface RegistrationFields {
	label: string
	type: string
	name: string
	required: boolean
	onBlur?: boolean
}

export const registrationFields = [
	{
		label: 'Фамилия',
		type: 'text',
		name: 'lastname',
		required: true,
	},
	{
		label: 'Имя',
		type: 'text',
		name: 'firstname',
		required: true,
	},
	{
		label: 'Email',
		type: 'email',
		name: 'email',
		onBlur: true,
		required: true,
	},
	{
		label: 'Телефон',
		type: 'text',
		name: 'phone',
		required: true,
	},
	{
		label: 'Компания',
		type: 'text',
		name: 'company',
		required: false,
	},
	{
		label: 'Пароль',
		type: 'password',
		name: 'password',
		onBlur: true,
		required: true,
	},
	{
		label: 'Подтвердите пароль',
		type: 'password',
		name: 'confirmPassword',
		required: true,
	},
]
