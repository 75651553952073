import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { NEW_USER_SUCCESS } from '@root-auth/utils/types'
import { showNotify } from '@root-auth/store/actions/notify/notify'

export default function* verify({ token }) {
	const res = yield call(fetchData, `/codes/verify/${token}`, res => res, null, {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
	})
	if (res.status === 'success') {
		yield put(
			showNotify({
				type: 'success',
				message: 'Учетная запись подтверждена!',
			})
		)
		yield put({ type: NEW_USER_SUCCESS, userSuccess: true })
	} else {
		yield put({ type: NEW_USER_SUCCESS, userSuccess: false })
	}
}
