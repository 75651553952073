import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { NEW_USER_SUCCESS } from '@root-auth/utils/types'
import { push } from 'react-router-redux'
import { showNotify } from '@root-auth/store/actions/notify/notify'

// Сага создания нового пользователя
export default function* newUser({ lastname, firstname, patronymic, email, password, phone, company }) {
	const resultData = {
		data: {
			phone,
			company,
		},
		lastname,
		firstname,
		patronymic,
		email,
		password,
	}
	try {
		const res = yield call(
			fetchData,
			'/users',
			res => res,
			null,
			{
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json',
			},
			'POST',
			JSON.stringify(resultData)
		)
		if (res.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: 'Спасибо за регистрацию! Вам отправлено электронное письмо для подтверждения',
					duration: 200000,
				})
			)

			yield put({ type: NEW_USER_SUCCESS, userSuccess: true })
		}
	} catch (err) {
		yield put(
			showNotify({
				type: 'error',
				message: 'Произошла ошибка',
			})
		)
		yield put({ type: NEW_USER_SUCCESS, userSuccess: false })
	}
}
