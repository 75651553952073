import { call, put } from 'redux-saga/effects'
import { SET_AUTH } from '@root-auth/utils/types'
import { fetchData } from '@root-auth/utils/common'
import { showNotify } from '@root-auth/store/actions/notify/notify'

export default function* onLogin({ login, password }) {
	try {
		const formData = new FormData()
		formData.append('login', login)
		formData.append('password', password)
		const res = yield call(
			fetchData,
			'/auth/login',
			res => res,
			null,
			{
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Basic ${btoa(login + ':' + password)}`,
			},
			'POST',
			formData
		)
		if (res.status === 'success') {
			yield put({
				type: SET_AUTH,
				login: true,
			})
		}
	} catch (err) {
		yield put(showNotify({ type: 'error', message: 'Неправильный логин и/или пароль' }))
	}
}
