import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { showNotify } from '@root-auth/store/actions/notify/notify'

export default function* passRecall({ login }) {
	const res = yield call(
		fetchData,
		'/passwords/recall',
		res => res,
		null,
		{
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json',
		},
		'POST',
		JSON.stringify({ login })
	)

	if (res.status === 'success') {
		yield put(
			showNotify({
				type: 'success',
				message: 'Проверьте Вашу почту',
			})
		)
	} else if (res.errors) {
		yield put(
			showNotify({
				type: 'error',
				message: res.errors.login.notExists,
			})
		)
	}
}
