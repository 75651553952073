import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { TextField, Checkbox } from '@material-ui/core'
import { newUser, validateNewUser } from '@root-auth/store/actions/auth/newUser'
import MuiButton from '../MuiComponents/MuiButton'
import InputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useInput } from '@root-auth/hooks/useInput'
import { registrationSchema } from '../../schems/registration.schema'
import { RegistrationFields, registrationFields } from '@root-auth/consts/registration.const'

export interface IFormData {
	[key: string]: string
}

const Registration: FC = () => {
	const { validateEmail, validatePassword, userSuccess } = useSelector((state: any) => state.auth)

	const { value, onChange } = useInput()
	const { register, handleSubmit, errors, setError } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(registrationSchema),
	})
	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		if (userSuccess) {
			setTimeout(() => {
				history.push('/login')
			}, 500)
		}
	}, [userSuccess])

	const onSubmit = (data: IFormData) => {
		if (validateEmail) {
			setError('email', {
				type: 'server',
				message: validateEmail,
			})
		} else if (validatePassword) {
			setError('password', {
				type: 'server',
				message: validatePassword,
			})
		} else {
			dispatch(
				newUser(
					value['lastname'],
					value['firstname'],
					value['email'],
					value['password'],
					value['phone'],
					value['company']
				)
			)
		}
	}

	useEffect(() => {
		if (validateEmail) {
			setError('email', {
				type: 'server',
				message: validateEmail,
			})
		}
	}, [validateEmail])

	useEffect(() => {
		if (validatePassword) {
			setError('password', {
				type: 'server',
				message: validatePassword,
			})
		}
	}, [validatePassword])

	return (
		<div className='login'>
			<Link to='/'>
				<img className='login__image' src='/assets/images/SVG/logo.svg' alt='LOGO' />
			</Link>
			<form className='login__form' noValidate onSubmit={handleSubmit(onSubmit)}>
				<h2 className='login__title'>Регистрация</h2>
				<div className='login__fields'>
					{registrationFields.map((field: RegistrationFields) =>
						field.name === 'phone' ? (
							<InputMask
								key={field.name}
								mask='+7(999)999-9999'
								value={value[field.name] && value[field.name]}
								onChange={onChange}
							>
								<TextField
									key={field.name}
									label={field.label}
									variant='outlined'
									value={value[field.name] && value[field.name]}
									onChange={onChange}
									fullWidth
									type={field.type}
									name={field.name}
									autoComplete='off'
									inputRef={register}
									inputProps={{ inputMode: 'tel' }}
									required={field.required}
									error={!!errors[field.name]}
									helperText={errors[field.name] && errors[field.name].message}
								/>
							</InputMask>
						) : (
							<TextField
								key={field.name}
								label={field.label}
								variant='outlined'
								fullWidth
								type={field.type}
								name={field.name}
								autoComplete='off'
								value={value[field.name] || ''}
								onChange={onChange}
								onBlur={() => value && field.onBlur && dispatch(validateNewUser(value['email'], value['password']))}
								inputProps={{ inputMode: 'text' }}
								inputRef={register}
								error={!!errors[field.name]}
								helperText={errors[field.name] && errors[field.name].message}
								required
							/>
						)
					)}

					<div
						className={`login__fields-submit-rules ${errors['rules'] && errors['rules'].message && 'error-message'}`}
					>
						<Checkbox name='rules' color='primary' inputRef={register} />Я соглашаюсь с
						<a href='https://gi-pro.ru/policy' className='text-align-center link link-blue'>
							{' '}
							правилами
						</a>{' '}
						использования порталом
					</div>
					{errors['rules'] && (
						<div className={`login__helper-text ${errors['rules'].message && 'error-message'}`}>
							{errors['rules'].message}
						</div>
					)}
				</div>
				<div className='login__actions'>
					<MuiButton fullWidth variant='contained' color='primary' type='submit'>
						Создать аккаунт
					</MuiButton>
					<div className='login__actions-item'>
						Уже есть аккаунт?{' '}
						<Link to='/login' className='link-blue'>
							Войти
						</Link>
					</div>
				</div>
			</form>
		</div>
	)
}
export default Registration
