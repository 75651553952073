import { Color } from '@material-ui/lab/Alert'
import { notifyActions } from '@root-auth/store/actions/notify/notify'
const notifyState = {
	isOpen: false,
	message: '',
	type: 'success',
	duration: 6000,
}

export interface NotifyAction {
	payload: { type: Color; message: string; duration?: number }
	type: string
}
export const notifyReducer = (state = notifyState, action: NotifyAction) => {
	switch (action.type) {
		case notifyActions.SHOW_NOTIFY:
			const { message, type, duration } = action.payload

			return {
				...state,
				isOpen: true,
				message,
				type,
				duration: duration ? duration : state.duration,
			}
		case notifyActions.CLOSE_NOTIFY:
			return {
				...state,
				isOpen: false,
				message: '',
				duration: 6000,
			}
		default:
			return state
	}
}
