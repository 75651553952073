import { ReactText } from 'react'
import { NEW_USER, VALIDATE_NEW_USER, VERIFY_NEW_USER, RESEND_VERIFY } from '@root-auth/utils/types'

export const newUser = (
	lastname: string,
	firstname: string,
	email: ReactText,
	password: ReactText,
	phone: ReactText,
	company: ReactText
) => {
	return {
		type: NEW_USER,
		lastname,
		firstname,
		email,
		password,
		phone,
		company,
	}
}

export const validateNewUser = (email: ReactText, password: ReactText) => {
	return {
		type: VALIDATE_NEW_USER,
		email,
		password,
	}
}

export const verifyNewUSer = (token: string) => {
	return {
		type: VERIFY_NEW_USER,
		token,
	}
}

export const resendVerify = (email: string) => {
	return {
		type: RESEND_VERIFY,
		email,
	}
}
