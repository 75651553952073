import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { SET_VALIDATE_NEW_USER } from '@root-auth/utils/types'
import { showNotify } from '@root-auth/store/actions/notify/notify'

// Сага валидации email и пароля нового пользователя
export default function* validateNewUser({ email, password }) {
	const resultData = {
		email,
		password,
	}

	const res = yield call(
		fetchData,
		'/users/validate',
		res => res,
		null,
		{
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json',
		},
		'POST',
		JSON.stringify(resultData)
	)

	if (res.errors?.email || res.errors?.password) {
		yield put({
			type: SET_VALIDATE_NEW_USER,
			validateEmail: res.errors.email?.main,
			validatePassword:
				res.errors.password?.needDigit ||
				res.errors.password?.small ||
				res.errors.password?.needLetter ||
				res.errors.password?.big,
		})
	}
	if (res.status === 'success') {
		yield put({
			type: SET_VALIDATE_NEW_USER,
			validateEmail: undefined,
			validatePassword: undefined,
		})
	}
}
