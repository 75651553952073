import React, { FC } from 'react'
import { ThemeProvider } from '@material-ui/core'
import { AutoLogin } from '@root-auth/components/Auth/AutoLogin'
import Login from '@root-auth/components/Auth/Login'
import Recall from '@root-auth/components/Auth/Recall'
import Registration from '@root-auth/components/Auth/Registration'
import Restore from '@root-auth/components/Auth/Restore'
import Verify from '@root-auth/components/Auth/Verify'
import Faq from '@root-auth/components/Faq'
import { Notify } from '@root-auth/components/Notify/Notify'
import rootReducer from '@root-auth/store/redusers/rootreducer'
import rootSaga from '@root-auth/store/sagas/root'
import '@root-auth/styles/main.scss'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { Redirect, Route, Router } from 'react-router-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { theme } from './styles/mui-theme'
declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}

export const history = createBrowserHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

// export const modeURL = process.env.NODE_ENV === 'development' ? `//localhost:3000/` : `https://dev.gi-pro.ru`
export const modeURL =
	process.env.NODE_ENV === 'development' ? `//localhost:3000/` : `https://${process.env.REACT_APP_ENV}`

sagaMiddleware.run(rootSaga)

const App: FC = () => {
	// const { isFilterOpen } = useSelector((state: IState) => state.maket)
	// const { accessToken } = useSelector((state: IState) => state.auth)
	// const dispatch = useDispatch()

	const accessToken = localStorage.getItem('access_token')
	if (accessToken) {
		window.location.href = modeURL
	}

	// useEffect(() => {
	// 	if (accessToken) {
	// 		dispatch(fetchPublicInfo())
	// 	} else {
	// 		dispatch(authorize())
	// 	}
	// }, [accessToken])

	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<Router history={history}>
					<Route
						exact
						path='/'
						render={() => {
							if (store.getState().auth.login) {
								return (window.location.href = modeURL)
							} else {
								return <Redirect to='/login' />
							}
						}}
					/>
					<Route path='/login' component={Login} />
					<Route path='/autologin' component={AutoLogin} />
					<Route path='/registration' component={Registration} />
					<Route path='/recall' component={Recall} />
					<Route path='/restore/:code' component={Restore} />
					<Route path='/verify/:code' component={Verify} />
					<Notify />
				</Router>
			</Provider>
		</ThemeProvider>
	)
}

export default App
