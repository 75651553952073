import { Color } from '@material-ui/lab/Alert'

export enum notifyActions {
	SHOW_NOTIFY = 'SHOW_NOTIFY',
	CLOSE_NOTIFY = 'CLOSE_NOTIFY',
}
export interface NotifyAction {
	type: Color
	message: string
	duration?: number
}
export const showNotify = ({ type, message, duration }: NotifyAction) => ({
	type: notifyActions.SHOW_NOTIFY,
	payload: { type, message, duration },
})

export const closeNotify = () => ({
	type: notifyActions.CLOSE_NOTIFY,
})
