import { takeLatest, all, takeEvery } from "redux-saga/effects"
import { LOGIN, NEW_USER, VALIDATE_NEW_USER, PASSWORD_RECALL, PASSWORD_RESTORE, VERIFY_NEW_USER, RESTORE_TOKEN, RESEND_VERIFY } from "@root-auth/utils/types"
import onLogin from "./onLogin"
import newUser from "./newUser"
import validateNewUser from "./validateNewUser"
import passRecall from "./passRecall"
import passRestore from "./passRestore"
import getRestoreToken from "./getRestoreToken"
import verify from "./verify"
import resendVerify from "./resendVerify"


function* watchLogin() {
  yield takeLatest(LOGIN, onLogin)
}

function* watchNewUser() {
  yield takeEvery(NEW_USER, newUser)
}

function* watchValidateNewUser() {
  yield takeEvery(VALIDATE_NEW_USER, validateNewUser)
}

function* watchPassRecall() {
  yield takeEvery(PASSWORD_RECALL, passRecall)
}

function* watchPassRestore() {
  yield takeEvery(PASSWORD_RESTORE, passRestore)
}

function* watchGetRestoreToken() {
  yield takeEvery(RESTORE_TOKEN, getRestoreToken)
}

function* watchVerify() {
  yield takeEvery(VERIFY_NEW_USER, verify)
}

function* watchResendVerify() {
  yield takeEvery(RESEND_VERIFY, resendVerify)
}

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchNewUser(),
    watchValidateNewUser(),
    watchPassRecall(),
    watchPassRestore(),
    watchGetRestoreToken(),
    watchVerify(),
    watchResendVerify()
  ])
}
