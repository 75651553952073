import {withStyles, Theme } from '@material-ui/core'
import Button from '@material-ui/core/Button'


const MuiButton = withStyles((theme: Theme) => ({
	root: {
		color: '#fff',
		fontSize: '1em',
		fontWeight: 'normal',
		textTransform: 'inherit',
		boxShadow: 'none',
		borderRadius: 6,
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	}
}))(Button)

export default MuiButton