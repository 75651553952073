export const LOGIN: string = 'LOGIN'
export const SET_AUTH: string = 'SET_AUTH'
export const NEW_USER: string = 'NEW_USER'
export const NEW_USER_SUCCESS: string = 'NEW_USER_SUCCESS'
export const VALIDATE_NEW_USER: string = 'VALIDATE_NEW_USER'
export const SET_VALIDATE_NEW_USER: string = 'SET_VALIDATE_NEW_USER'
export const VERIFY_NEW_USER: string = 'VERIFY_NEW_USER'
export const RESEND_VERIFY: string = 'RESEND_VERIFY'
export const PASSWORD_RECALL: string = 'PASSWORD_RECALL'
export const SET_VALIDATE_RECALL: string = 'SET_VALIDATE_RECALL'
export const PASSWORD_RESTORE: string = 'PASSWORD_RESTORE'
export const RESTORE_TOKEN: string = 'RESTORE_TOKEN'
export const NEW_PASSWORD_SUCCESS: string = 'NEW_PASSWORD_SUCCESS'
export const SET_RESTORE_TOKEN: string = 'SET_RESTORE_TOKEN'