import {
	SET_AUTH,
	NEW_USER_SUCCESS,
	SET_RESTORE_TOKEN,
	SET_VALIDATE_NEW_USER,
	NEW_PASSWORD_SUCCESS,
} from '@root-auth/utils/types'

export const authState: any = {
	login: false,
	userSuccess: undefined,
	newPasswordSuccess: false,
	restoreToken: null,
	validateEmail: null,
	validatePassword: null,
}

interface IAction {
	type: string
	login: boolean
	userSuccess: boolean | undefined
	restoreToken: string | undefined
	validateEmail?: string | undefined
	validatePassword?: string | undefined
	validateRecall?: string | undefined
	newPasswordSuccess: boolean
}

export const authReducer = (state = authState, action: IAction) => {
	switch (action.type) {
		case SET_AUTH:
			return {
				...state,
				login: action.login,
			}
		case NEW_USER_SUCCESS:
			return {
				...state,
				userSuccess: action.userSuccess,
			}
		case SET_RESTORE_TOKEN:
			return {
				...state,
				restoreToken: action.restoreToken,
			}
		case SET_VALIDATE_NEW_USER:
			return {
				...state,
				validateEmail: action.validateEmail,
				validatePassword: action.validatePassword,
			}
		case NEW_PASSWORD_SUCCESS:
			return {
				...state,
				newPasswordSuccess: action.newPasswordSuccess,
			}
		default:
			return state
	}
}
