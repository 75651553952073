import * as yup from 'yup'
export const registrationSchema = yup.object().shape({
	firstname: yup
		.string()
		.matches(/^([^0-9]*)$/, 'В имени должны быть только буквы')
		.required('Поле должно быть заполненно')
		.min(3, 'Имя должно состоять минимум из 3 символов'),
	lastname: yup
		.string()
		.matches(/^([^0-9]*)$/, 'В фамилии должны быть только буквы')
		.required('Поле должно быть заполненно')
		.min(3, 'Фамилия должна состоять минимум из 3 символов'),
	phone: yup
		.string()
		.matches(/\+\d{1}\(\d{3}\)\d{3}-\d{4}/, 'Некорректный  телефон')
		.required('Поле должно быть заполненно'),
	email: yup
		.string()
		.matches(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+/, 'Некорректный  Email')
		.required('Поле должно быть заполненно'),
	company: yup.string(),
	password: yup.string().required('Поле должно быть заполненно'),
	confirmPassword: yup
		.string()
		.required('Введите пароль повторно')
		.oneOf([yup.ref('password')], 'Пароли должны совпадать'),
	rules: yup.boolean().oneOf([true], 'Обязательное поле'),
})
