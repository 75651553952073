import { LOGIN } from '@root-auth/utils/types'


export const onLogin = (login: string, password: string | number) => {
	return {
		type: LOGIN,
		login,
		password
	}
}
