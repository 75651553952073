import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { NEW_PASSWORD_SUCCESS } from '@root-auth/utils/types'
import { showNotify } from '@root-auth/store/actions/notify/notify'

export default function* passRestore({ password, token }) {
	try {
		const res = yield call(
			fetchData,
			'/passwords/set',
			res => res,
			null,
			{
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json',
			},
			'POST',
			JSON.stringify({ password, token })
		)
		if (res.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: 'Новый пароль сохранен',
				})
			)
			yield put({ type: NEW_PASSWORD_SUCCESS, newPasswordSuccess: true })
		} else {
			yield put({ type: NEW_PASSWORD_SUCCESS, newPasswordSuccess: false })
		}
	} catch (err) {
		yield put(
			showNotify({
				type: 'error',
				message: 'Произошла ошибка',
			})
		)
	}
}
