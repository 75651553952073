import { PASSWORD_RESTORE, RESTORE_TOKEN } from '@root-auth/utils/types'


export const passRestore = (password: string, token: string) => {
	return {
		type: PASSWORD_RESTORE,
		password,
		token
	}
}

export const getRestoreToken = (token: string) => {
	return {
		type: RESTORE_TOKEN,
		token
	}
}