import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TextField } from '@material-ui/core'
import { Link, Redirect, Route, Router } from 'react-router-dom'
import { onLogin } from '@root-auth/store/actions/auth/onLogin'
import { modeURL } from '@root-auth/App'
import './Login.scss'

const AutoLogin: React.FC = () => {
	const loginFromState = useSelector((state: any) => state.auth.login)
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const dispatch = useDispatch()
	const location = useLocation()

	useEffect(() => {
		if (location.pathname.slice(1) === 'autologin') {
			setTimeout(() => {
				setPassword('demo-gipro0')
				setLogin('demo@gi-pro.ru')
				dispatch(onLogin('demo@gi-pro.ru', 'demo-gipro0'))
			}, 300)
		}
	}, [location])

	useEffect(() => {
		if (loginFromState) window.location.href = modeURL
	}, [loginFromState])

	return (
		<div className='login'>
			<Link to='/'>
				<img className='login__image' src='/assets/images/SVG/logo.svg' alt='LOGO' />
			</Link>
			<form className='login__form'>
				<h2 className='login__title'>Авторизация</h2>
				<div className='login__fields'>
					<TextField
						label='Email'
						variant='outlined'
						fullWidth
						type='email'
						name='email'
						onChange={e => setLogin(e.currentTarget.value)}
						value={login}
						inputProps={{ autoComplete: 'on', inputMode: 'email' }}
					/>
					<TextField
						label='Пароль'
						variant='outlined'
						fullWidth
						type='password'
						name='password'
						onChange={e => setPassword(e.currentTarget.value)}
						value={password}
						inputProps={{ autoComplete: 'on', inputMode: 'text' }}
					/>
				</div>
			</form>
		</div>
	)
}
export { AutoLogin }
