import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { closeNotify } from '@root-auth/store/actions/notify/notify'

export const Notify = () => {
	const { isOpen, type, message, duration } = useSelector((state: any) => state.notify) // todo any
	const dispatch = useDispatch()

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return
		}
		dispatch(closeNotify())
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={isOpen}
			autoHideDuration={duration}
			onClose={handleClose}
		>
			<Alert variant='filled' onClose={handleClose} severity={type}>
				{message}
			</Alert>
		</Snackbar>
	)
}
