import { call, put } from 'redux-saga/effects'
import { fetchData } from '@root-auth/utils/common'
import { showNotify } from '@root-auth/store/actions/notify/notify'

export default function* resendVerify({ email }) {
	try {
		const res = yield call(
			fetchData,
			`/users/resend-verify`,
			res => res,
			null,
			{
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json',
			},
			'POST',
			JSON.stringify({ email })
		)
		if (res.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: 'Проверьте Вашу почту',
				})
			)
		}
	} catch (err) {
		yield put(
			showNotify({
				type: 'error',
				message: 'Произошла ошибка',
			})
		)
	}
}
